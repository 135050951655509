



















































import { Notify,Toast } from "vant"
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import ListView from "@/components/ShareComponent/PullDownUp.vue"
import ViewBottom from "@/components/ShareComponent/ListBottom.vue"
import ShopItem from "@/components/ShopComponent/OrderItem.vue"
import ExpressItem from "@/components/ExpressComponent/MyExpressComponent.vue"
import { IndexStore } from "@/store/modules/Index"
import { ShopOrderSucType,MyComponentType } from "@/Type/index"
import { MyOrderList } from "@/Api/Shop/index"
import { MyExpress } from "@/Api/Express/index"
import Stroage from "@/util/Storage"
import { Vue,Component,Watch } from "vue-property-decorator"
import ZoomPage from "@/impView/PageSafety";

interface AllListType<T=string>{
    loadingShow:boolean
    NavList:Array<string>
    NavIndex:number
    userId:T|number;
    token:T;
    ShopUpData:{ pageNo:number;pageSize:number }
    ExpUpData:{ pageNo:number;pageSize:number }
    ShopList:Array<any>
    ExpList:Array<any>
    StopUpDown:boolean
    UpDown:boolean
    PullDown:boolean

    initData():void;
    handleChangeNav(index:number):void;
    handlePullDown():void;
    handlePullUpDown():void;
    handleUpShopData(bool?:boolean):void;
    handleUpExpressData(bool?:boolean):void;
    handleError(data:string):void;
}

@Component({ name:"AllList",components:{ ListView,ViewBottom,ShopItem,ExpressItem,HeadTop } })
export default class AllList extends ZoomPage implements AllListType{
    loadingShow = true
    NavList = [ "校园外卖","代取快递" ]
    NavIndex = 0
    userId:string|number = ""
    token = ""
    ShopUpData = {
        pageNo:1,
        pageSize:15
    }
    ExpUpData = {
        pageNo:1,
        pageSize:15
    }
    ShopList:Array<ShopOrderSucType> = []
    ExpList:Array<MyComponentType> = []
    StopUpDown = false
    UpDown = true
    PullDown = false

    mounted() {
        this.loadingShow = false
        if (!IndexStore.GetLoading) return;
        this.initData()
    }

    initData(){
        this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
        this.token = Stroage.GetData_ && Stroage.GetData_("token")
        if( this.NavIndex ){
            this.ExpUpData.pageNo = 1
            this.handleUpExpressData(true)
        }else{
            this.ShopUpData.pageNo = 1
            this.handleUpShopData(true)
        }
    }

    handleChangeNav(index:number){
        if (!IndexStore.GetLoading){
            Toast({
           message:"请先登录",
           icon:"none",
           className:"ToastClassName"
           })
             return;
        }
        if( this.NavIndex != index ){
            this.loadingShow = true
            this.UpDown = true
            this.NavIndex = index
            this.initData()
        }
    }

    handlePullDown(){
        this.PullDown = false
        this.StopUpDown = false
        if( this.NavIndex ){
            this.ExpUpData.pageNo = 1
            this.handleUpExpressData(true)
        }else{
            this.ShopUpData.pageNo = 1
            this.handleUpShopData(true)
        }
    }

    handlePullUpDown(){
        if( this.StopUpDown )return;
        this.UpDown = true
        if( this.NavIndex ){
           this.ExpUpData.pageNo += 1
           this.handleUpExpressData()
        }else{
            this.ShopUpData.pageNo += 1
            this.handleUpShopData()
        }
    }

    handleUpShopData(bool?:boolean){
        this.loadingShow = true
        MyOrderList({
            userId:this.userId,
            token:this.token
        },this.ShopUpData).then(res=>{
            this.loadingShow = false
            this.PullDown = true
            this.UpDown = false
            if( res.message.code === "200" ){
                if( res.data.orderList.length < this.ShopUpData.pageSize ){
                    this.StopUpDown = true
                }
                if( bool ){
                    this.ShopList = res.data.orderList
                }else{
                    this.ShopList = this.ShopList.concat( res.data.orderList )
                }
            }else{
                this.handleError(res.message.msg)
            }
        })
    }

    handleUpExpressData(bool?:boolean){
        this.loadingShow = true
        MyExpress({
            userId:this.userId,
            token:this.token
        },this.ExpUpData).then(res=>{
            this.loadingShow = false
            this.PullDown = true
            this.UpDown = false
            if( res.message.code === "200" ){
                if( res.data.length < this.ExpUpData.pageSize ){
                    this.StopUpDown = true
                }
                if( bool ){
                    this.ExpList = res.data
                }else{
                    this.ExpList = this.ExpList.concat( res.data )
                }
            }else{
                this.handleError(res.message.msg)
            }
        })
    }

    handleError( data:string ){
       let str = ""
       if ( data.length ){
           str = data
       }else{
           str = "网络繁忙..."
       }
       Notify({
           message: str,
           color: 'white',
           background: "rgba(0,0,0,.6)",
       });
   }

   get GetLoading() {
       return IndexStore.GetLoading;
   }

   @Watch("GetLoading")
   handleChange(newval: any) {
       if (newval) {
           this.initData();
       }
   }
}
